import { type FC, StrictMode } from "react";

import { Global, css } from "@emotion/react";
import { ErrorBoundary } from "@relatable/ui/ErrorBoundary";
import { UIProvider } from "@relatable/ui/UIProvider";
import { createRoot } from "react-dom/client";

import { Unsubscribe } from "./Unsubscribe";

const GlobalStyles = css`
  html {
    background-color: black;
  }

  a {
    cursor: pointer;
  }
`;

const Root: FC = () => (
  <ErrorBoundary onError={() => {}}>
    <UIProvider>
      <Global styles={GlobalStyles} />
      <Unsubscribe />
    </UIProvider>
  </ErrorBoundary>
);

let container: HTMLElement | null = null;

// https://stackoverflow.com/a/72139826/11661273
document.addEventListener("DOMContentLoaded", () => {
  console.log(`Unsubscribe app [${process.env.COMMIT_HASH}] ${process.env.BUILD_DATE}`);

  if (!container) {
    container = document.getElementById("root") as HTMLElement;
    const root = createRoot(container!);
    root.render(
      <StrictMode>
        <Root />
      </StrictMode>
    );
  }
});
