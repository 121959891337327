import {
  type CSSProperties,
  type FC,
  type MouseEvent,
  type PropsWithChildren,
  type ReactElement,
  useState
} from "react";

import styled from "@emotion/styled";
import { Button as MuiButton, type ButtonProps as MuiButtonProps } from "@mui/material";
import { Link } from "react-router-dom";

import { LinearLoader } from "./LinearLoader";
import { TooltipWrapper } from "./TooltipWrapper";

interface ButtonBaseProps {
  isLoading?: boolean;
  variant?: MuiButtonProps["variant"];
  color?: MuiButtonProps["color"];
  type?: MuiButtonProps["type"];
  tooltipText?: string;
  disabled?: boolean;
  size?: "full" | "medium" | "auto";
  className?: string;
  style?: CSSProperties;
  icon?: ReactElement;
  target?: string;
  id?: string;
  linkStyle?: CSSProperties;
}

export const Button: FC<
  PropsWithChildren<
    ButtonBaseProps &
      (
        | {
            onClick: (e: MouseEvent<HTMLButtonElement>) => void;
          }
        | {
            href: string;
          }
        | {
            onAsyncClick: (e: MouseEvent<HTMLButtonElement>) => Promise<void | any>;
          }
      )
  >
> = ({
  children,
  disabled,
  tooltipText = "",
  variant = "contained",
  color = "secondary",
  size = "auto",
  className,
  type,
  style,
  icon,
  id,
  linkStyle,
  target,
  ...rest
}) => {
  const Wrapper =
    "href" in rest
      ? (props: any) => (
          <Link style={{ ...linkStyle }} to={rest.href} target={target}>
            {props.children}
          </Link>
        )
      : (props: any) => props.children;

  const [isAsyncClickLoading, setIsAsyncClickLoading] = useState(false);
  const isLoading = Boolean(isAsyncClickLoading || rest.isLoading);
  return (
    <Wrapper>
      <TooltipWrapper fullWidth={size === "full"} tooltipText={tooltipText}>
        <SButton
          startIcon={icon}
          id={id}
          type={type}
          fullWidth={size === "full"}
          disabled={disabled || isLoading}
          variant={variant}
          color={color}
          className={className}
          style={{ width: size === "medium" ? 200 : "", ...style }}
          onClick={async e => {
            if (isLoading || "href" in rest) return;
            if ("onClick" in rest) {
              rest.onClick(e);
            } else {
              setIsAsyncClickLoading(true);
              await rest.onAsyncClick(e);
              setIsAsyncClickLoading(false);
            }
          }}
        >
          <LinearLoader
            color="secondary"
            style={{ width: "100%", position: "absolute", top: 0, height: 1 }}
            visible={isLoading}
          />

          {children}
        </SButton>
      </TooltipWrapper>
    </Wrapper>
  );
};

const SButton = styled(MuiButton)`
  color: ${p => (p.color === "info" ? "white" : "")};
  text-transform: none;
  position: relative;
`;
