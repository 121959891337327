import { type FC, useState } from "react";

import styled from "@emotion/styled";
import { Button } from "@relatable/ui/Button";
import { palette } from "@relatable/ui/Palette";
import { useSnackbar } from "@relatable/ui/Snackbar";
import { useDocumentTitle } from "@relatable/ui/hooks/useDocumentTitle";
import { LogoInline } from "@relatable/ui/icons/LogoInline";

const Page = styled.div`
  padding: 0 15%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;

  .nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 20px;

    .sub-nav {
      display: flex;
      align-items: center;
      @media only screen and (max-width: 750px) {
        display: none;
      }
    }

    a {
      color: white;
      font-size: 1.1rem;
    }

    .link-box {
      text-transform: uppercase;
      border: 1px solid white;
      padding: 15px;
      border-radius: 5px;
      font-size: 1rem;
      transition: all 500ms ease;
      :hover {
        color: black;
        background-color: white;
      }
    }
  }

  input {
    color: white;
    border: 1px solid white !important;
    border-radius: 5px;
    margin-top: 20px;
    padding: 17px;
    outline: 0;
    background: transparent;
  }

  button {
    margin-left: auto;
    margin-top: 20px;
    background: white;
    color: black;
    font-size: "1.1rem";
    :hover {
      background: ${palette.gray[40]};
    }
  }
`;

export const Unsubscribe: FC = () => {
  useDocumentTitle("Relatable Unsubscribe");
  const snackbar = useSnackbar();

  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const unsubscribe = async () => {
    setIsLoading(true);
    if (!process.env.GRAPH_URL) throw Error("missing GRAPH_URL");
    try {
      const res = await fetch(`${process.env.GRAPH_URL}/unsubscribe`, {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-type": "application/json"
        }
      });
      if (!res.ok) {
        const results = await res.json();
        throw new Error(results.message);
      }
      setEmail("");
      snackbar.success("You have been unsubscribed");
    } catch (err) {
      console.log(err);
      if (err instanceof Error) {
        snackbar.error(err.message);
        return;
      }
      snackbar.error(
        "Something went wrong. It might be either the given email is invalid or it is already unsubscribed"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page>
      <div className="nav">
        <a href="https://relatable.me/" style={{ marginLeft: 0 }}>
          <LogoInline color="white" style={{ width: 150 }} />
        </a>

        <div className="sub-nav">
          <a href="https://relatable.me/#services">Services</a>
          <a style={{ margin: "0 30px" }} href="https://relatable.me/#about">
            About
          </a>
          <a href="https://relatable.me/#get-started" className="link-box">
            Get started
          </a>
        </div>
      </div>

      <h1>Are you sure you want to unsubscribe?</h1>
      <p>
        We&apos;re working with some of the most demanding brands and marketing teams on the planet.
      </p>

      <img alt="partner" style={{ width: "100%" }} src="/partners.png" />

      <form onSubmit={unsubscribe} style={{ display: "flex", flexDirection: "column" }}>
        <input
          type="email"
          onChange={e => setEmail(e.target.value)}
          value={email}
          placeholder="email…"
        />

        <Button type="submit" isLoading={isLoading} size="medium" onClick={unsubscribe}>
          Unsubscribe
        </Button>
      </form>
    </Page>
  );
};
